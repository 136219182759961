/* DASHBOARD LAYOUT */

div.topPanel {
  padding: 1rem;
  padding-bottom: 0.5rem;
}

div.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

div.flex {
  display: flex;
  flex-grow: 0;
}

div.flexCol {  
  flex-direction: column;
  flex-basis: 1;
}

div.leftPanel {
  background: #00000056;
  flex-grow: 0.3;
  margin-right: 0.5rem;
}

div.rightPanel {
  background: #00000056;
  flex-grow: 1;
}

/* DASBOARD CONTENTS */

h2 {
  padding: 0.5rem;
  margin: 0.5rem;
}

h3 {
  padding: 2rem;
  margin: 0.5rem;
}

/* NOTIFICATIONS */

ul.notifications {
  background: orange;
  color: black;
}
