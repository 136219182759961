div.htmlElement {
  /* background: #2e2e2eb9; */
  background: black;
  width: 512px;
  height: 512px;
}

div.htmlElement h3 {
  /* margin: 2rem; */
  color: white;
  padding: 0.2rem;
  font-family: "Ubuntu", sans-serif;
}

div.htmlElement p {
  color: white;
  margin: 0.5rem;
  padding: 1rem;
  padding-top: 0;
  font-family: 'Anonymous Pro', monospace;
}

nav.manifesto {
  max-width: 700px;
  -webkit-animation-name: shadowDark;
          animation-name: shadowDark;
  margin-top: 0;
}

div.manifesto.container {
  padding: 1rem;
  padding-bottom: 1.5rem;
  box-shadow: 0vw 1vh 5px black;
}

div.manifesto.container h1,
p {
  font-family: "Anonymous Pro", monospace;
  color: white;
}

h1.manifesto.title {
  font-family: "Anonymous Pro", monospace;
  margin-top: 0.5rem;
  font-size: 1rem;
}

p.paragraphLink {
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  margin: 0;
}

p.paragraphContent {  
  max-width: 700px;
}

div.paragraphLinks {
  padding: 1rem;
  padding-top: 0;
}

div.paragraphLinks li :hover {
  background-color: #ce1ca7;
}

button.manifestHeader {
  margin: auto;
  outline: none;
  border: 0;
}

div.tabHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

button.tabButton {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  text-decoration: none;
}

button.tabButton.titleButton {
  margin-right: auto; /*used to override justify-content; justify-self is cooming soon though*/
  /* font-size: 1.5em; */
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}

button.tabButton.titleButton:focus {
  outline: none;
}

button.manifestHeader:focus {
  outline: none;
}

button.pageflip {
  font-size: 1em;
  max-width: 20vw;
  padding-right: 0.6rem;
}

button.pageflip:hover {
  text-decoration: underline;
}

button.titleButton:hover {
  border-left: dashed;
  border-width: 0.1em;
}

div.manifestContent {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 1.1;
}

@media screen and (min-width: 1000px) {
  div.manifestContent {
    line-height: 1.3;
  }
}

div.manifestContent p {
  margin-bottom: 1.5rem;
}

/* GENERAL */
div.content {
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  padding-top: 1.5rem;
}

/* h1.productHeader {
  width: 100%;
  text-align: center;
} */

div.productHeader {
  background: #272727bc;
  -webkit-animation: infinite;
          animation: infinite;
  -webkit-animation-name: shadowDark;
          animation-name: shadowDark;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}

@-webkit-keyframes flicker {
  0%,25%,27%,29%,77%,80%,100% {
    text-shadow: 0.4vw 0.4vh 1rem rgb(55, 248, 255), 0.2vw 0.2vh rgb(255, 55, 121);
  }
  26%,28%,78% {
    text-shadow: none;
  }
}

@keyframes flicker {
  0%,25%,27%,29%,77%,80%,100% {
    text-shadow: 0.4vw 0.4vh 1rem rgb(55, 248, 255), 0.2vw 0.2vh rgb(255, 55, 121);
  }
  26%,28%,78% {
    text-shadow: none;
  }
}

h1.productHeader {
  padding: 2vh 5vw 2vh 5vw;
	position: relative;
  text-align: center;
  font: 3em Helvetica, Sans-Serif;
	letter-spacing: -5px;
  color: rgba(255, 255, 255, 0.918);
  -webkit-animation: infinite;
          animation: infinite;
  -webkit-animation-name: flicker;
          animation-name: flicker;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}

.flex-container {
  padding: 0;
  margin: auto;

  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  justify-content: center;
}

.flex-item {
  padding: 0rem;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  color: white;
  font-size: 1em;
  text-align: center;
}

/* OUTRO PRODUCT LIST */
li.flex-item {
  height:auto;
  padding: 0.5rem;
  flex-grow: 1;
  background: #272727bc;
}

div.listedOutro {
  padding: 1rem;
}

img.listedOutro {  
  width: 100%;
  height:100%;
}

ul.flex-container {
  padding-top: 0rem;
}

/* OUTRO CUTENESS */
.flex-container.outro {
  max-width: 1200px;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-item.outro { 
  max-width: 400px;
  width: auto;
  height: auto;
  background: #272727bc;
  padding: 1rem;
}

img.flex-item.outro {
  max-width:95%;
  max-height:95%;
  background: none;
  opacity: 0.9;
  padding: 0;
}

div.flex-item.outro > p {
  text-align: left;
  line-height: 120%;
  font-size: 1em;
  font: 1rem Helvetica, ‘sans-serif’;
  letter-spacing: 2px;
}

#outroHeader {
  padding: 3vh;
  margin-bottom: -3vh;
  line-height: 1.5em;
  text-align: center;
  background: none;
  font-size : 1rem;
}

/* #outroDescription {
  align-self: center;
} */




/* COMMON */

div.outerContainer {
  margin: 2vh 5%;
  background: #272727bc;
}

div.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.post {
  background: #2e2e2e52;
  width: 90%;
  max-width: 700px;
  margin: 1rem;
}

div.post a {
  text-decoration: none;
}

/* div.post h3,
h5 {
  font-family: "Ubuntu", sans-serif;
} */

/* div.post span {
  color: red;
  font-size: 1em;
  font-family: 'News Cycle', sans-serif;
} */

/* POST ABSTRACT STYLING */

/* inplace */
div.postAbstract {
  background: #2e2e2e81;
  padding: 0.5rem;
  margin: 0.5rem;
  width: 95%;
  max-width: 650px;
  line-height: 1.2;
  padding-bottom: 1rem;
}

@media screen and (min-width: 1000px) {
  div.postAbstract {
    margin: 1.5rem;
    min-width: 700px;
  }
  
  div.abstractContent span
  {
    line-height: 1.4;
  }
}

/* abstract header */

div.postAbstract a {
  text-decoration: none;
}

div.abstractHeader {
  -webkit-animation: infinite;
          animation: infinite;
  -webkit-animation-name: shadowDark;
          animation-name: shadowDark;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}

div.abstractHeader {
  color: ghostwhite;
}

#abstractTitle span {
  font-size: 1.3rem;
}

span.abstractDetails {
  font-family: serif;
  font-family: initial;
  font-size: 0.9em;
  /* font-style: italic; */
  /* padding-top: 1vh; */
}

span.abstractDetails.author {
  color: #5ef083;
}

span.abstractDetails.date {
  color: inherit;
}


/* abstract content */

div.abstractContent {
  color: #f1f1f1;
  text-shadow: #232323 2px 5px 10px;
  padding: 1rem 2rem;
}

div.abstractContent span {
  font-family: 'News Cycle', sans-serif;
}

/* abstract footer */

div.abstractFooter {
  /* background-color: red */
  width: 100%;
  display: flex;    
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.2rem 2rem;
  /* padding: 0.5rem;   */
  align-items: center
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: #252525ef;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: rgb(56, 56, 56);
}

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  background: #1b1b1b;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #444444ea;
  color: rgb(248, 32, 32);
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ffd000;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid rgb(0, 0, 0);
  background: rgb(37, 37, 37);
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgb(31, 31, 31);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.draftJsEmojiPlugin__emojiSelect__34S1B {
    display: inline-block;
  }
  
  .draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    margin: 0;
    padding: 0;
    width: 2.5em;
    height: 1.5em;
    box-sizing: border-box;
    line-height: 1.2em;
    font-size: 1.5em;
    color: #ffd000;
    background: rgb(32, 32, 32);
    border: 1px solid rgb(7, 7, 7);
    cursor: pointer;
  }
  
  .draftJsEmojiPlugin__emojiSelectButton__3sPol:focus, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:focus {
    outline: 0;
    /* reset for :focus */
  }
  
  .draftJsEmojiPlugin__emojiSelectButton__3sPol:hover, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:hover {
    background: #202020;
  }
  
  .draftJsEmojiPlugin__emojiSelectButton__3sPol:active, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:active {
    background: #202020;
  }
  
  .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    background: #202020;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
    margin-top: 10px;
    padding: 0 .3em;
    position: absolute;
    z-index: 1000;
    box-sizing: content-box;
    background: rgba(37, 37, 37, 0.925);
    border: 1px solid #252525;
    box-shadow: 0 4px 30px 0 rgb(22, 22, 22);
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverClosed__3Kxxq {
    display: none;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverTitle__3tpXz {      
    font-family: "Anonymous Pro", monospace;
    margin: 0 0 .3em;
    padding-left: 1em;
    height: 1.5em;
    line-height: 1.5em;
    font-weight: normal;
    font-size: 1em;
    color: #ffd000;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
    margin: 0 0 .3em;
    position: relative;
    z-index: 0;
    width: 21em;
    height: 20em;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE {
    padding: 0 .5em;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE:first-child .draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
    display: none;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
    margin: 1em 0;
    padding-left: .5em;
    font-weight: normal;
    font-size: 1em;
    color: #ffd000;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroupList__HQ8_y {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroupItem__2pFOS {
    width: 2.5em;
    height: 2.5em;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverToneSelect__28bny {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverToneSelectList__haFSJ {
    margin: .3em;
    padding: .3em;
    position: absolute;
    display: flex;
    list-style: none;
    border: 1px solid #e0e0e0;
    border-radius: .5em;
    background: rgb(235, 39, 241);
    box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL {
    width: 2.5em;
    height: 2.5em;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL:first-child {
    border-right: 1px solid #e0e0e0;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ, .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
    padding: 0;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
    background-color: #efefef;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
    margin: 0;
    padding: 0 .5em;
    display: flex;
    width: 20em;
    list-style: none;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
    width: 2.5em;
    height: 2.5em;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverNavEntry__1OiGB, .draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    color: #bdbdbd;
    background: none;
    border: none;
    outline: none;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
    color: #42a5f5;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
    position: absolute;
    right: 0;
    top: .3em;
    bottom: .3em;
    width: .25em;
    background-color: #e0e0e0;
    border-radius: .125em;
    opacity: .1;
    -webkit-transition: opacity .4s;
    transition: opacity .4s;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverScrollbarThumb__jGYdG {
    background-color: #000;
    border-radius: .125em;
    cursor: pointer;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m:hover .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
    opacity: .3;
  }
  
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:hover,
  .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:active {
    opacity: .6;
  }
  .draftJsEmojiPlugin__emoji__2oqBk {
    background-position: center;
    /* make sure the background the image is only shown once */
    background-repeat: no-repeat;
    background-size: contain;
    /* move it a bit further down to align it nicer with the text */
    vertical-align: middle;
  
    /*
    We need to limit the emoji width because it can be multiple characters
    long if it is a 32bit unicode. Since the proper width depends on the font and
    it's relationship between 0 and other characters it's not ideal. 1.95ch is not
    the best value, but hopefully a good enough approximation for most fonts.
    */
    display: inline-block;
    overflow: hidden;
    max-width: 1.95ch;
    /*
    Needed for iOS rendering to avoid some icons using a lot of height without
    actually needing it.
    */
    max-height: 1em;
    line-height: inherit;
    margin: -.2ex 0em .2ex;
    /*
    In the past we used opacity: 0 to hide the original Emoji icon no matter what
    system it is. Recently we switched to color: transparent since it started to
    work in recent iOS version.
    */
    color: transparent;
  
    /*
    Some SVG files (say 2764 for :heart:) don't have default width/height, thus
    may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
    Chrome 61).
    */
    min-width: 1em;
  }
  .draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {    
    padding: 5px 10px 1px 10px;
    -webkit-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
    transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  }
  
  .draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
    background-color: #2c2c2c;
  }
  
  .draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
    background-color: #2c2c2c;
  }
  
  .draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
    font-family: "Anonymous Pro", monospace;
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 368px;
    color: pink;
    font-size: 0.9em !important;
    /* font-size: 0.9em; */
  }
  
  .draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    display: inline-block;
  }
  .draftJsEmojiPlugin__emojiSuggestions__2ffcV {
    border: 1px solid rgb(24, 24, 24);
    margin-top: 1.75em;
    position: absolute;
    min-width: 220px;
    max-width: 440px;
    background: rgba(27, 27, 27, 0.966);
    border-radius: 2px;
    box-shadow: 0px 4px 30px 0px rgb(31, 31, 31);
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  
.draftJsFocusPlugin__unfocused__1Wvrs {
    padding: 15px;
    cursor: default;
  }

.draftJsFocusPlugin__unfocused__1Wvrs:hover {
    cursor: default;
    border-radius: 10px;
    box-shadow: 0 0 0 3px rgb(255, 171, 14);
    padding: 15px;
  }
  
  .draftJsFocusPlugin__focused__3Mksn {
    cursor: default;
    border-radius: 10px;
    box-shadow: 0 0 0 3px rgb(22, 127, 255);
    padding: 15px;
  }
  
.toolbar {
    border: 1px solid #111;
    background: #333;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
    z-index: 2;
    box-sizing: border-box;
  }
  
  .toolbar:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #333;
    border-width: 4px;
    margin-left: -4px;
  }
  
  .toolbar:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #111;
    border-width: 6px;
    margin-left: -6px;
  }
.buttonWrapper {
    display: inline-block;
  }
  
  .button {
    background: #333;
    color: #ddd;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    border-radius: 4px;
  }
  
  .button svg {
    fill: #ddd;
  }
  
  .button:hover, .button:focus {
    background: #444;
    outline: 0; /* reset for :focus */
  }
  
  .active {
    color: #313131;
  }
  
  .active svg {
    fill: #ffbb00;
  }
.draftJsMentionPlugin__input__1Wxng {
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #444;
  }
  
  .draftJsMentionPlugin__input__1Wxng:focus {
    outline: none;
  }
  
  .draftJsMentionPlugin__input__1Wxng::-webkit-input-placeholder {
    color: #888;
  }
  
  .draftJsMentionPlugin__input__1Wxng::-moz-placeholder {
    color: #888;
  }
  
  .draftJsMentionPlugin__input__1Wxng::-ms-input-placeholder {
    color: #888;
  }
  
  .draftJsMentionPlugin__input__1Wxng::placeholder {
    color: #888;
  }
  
  .draftJsMentionPlugin__inputInvalid__X9hHv {
    color: #e65757;
  }
  
  .draftJsMentionPlugin__link__TQHAX > span {
    color: #2996da;
    text-decoration: underline;
  }

  .input {
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #ddd;
  }
  
  .input:focus {
    outline: none;
  }
  
  .input::-webkit-input-placeholder {
    color: #aaa;
  }
  
  .input::-moz-placeholder {
    color: #aaa;
  }
  
  .input::-ms-input-placeholder {
    color: #aaa;
  }
  
  .input::placeholder {
    color: #aaa;
  }
  
  .inputInvalid {
    color: #e65757;
  }
  
  .link {
    color: #2996da;
    text-decoration: underline;
  }

/* draft js */

.DraftEditor-root figure
{
    margin: 0;
}

.DraftEditor-root img {
    width: 100%;
    padding: 1.5vh 0;
}

.DraftEditor-root iframe {
    display: block;
    width: 80%;
    height: 30vh;
    margin-left:10%;
    padding: 1.5vh 0;
}
/* draft js */

.editor .public-DraftEditor-content {
    min-height: 40px;
    /* padding: 2.5vh 0.4rem 1.5vh 0.4rem; */
    width: 100%;
}
/* DASHBOARD LAYOUT */

div.topPanel {
  padding: 1rem;
  padding-bottom: 0.5rem;
}

div.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

div.flex {
  display: flex;
  flex-grow: 0;
}

div.flexCol {  
  flex-direction: column;
  flex-basis: 1;
}

div.leftPanel {
  background: #00000056;
  flex-grow: 0.3;
  margin-right: 0.5rem;
}

div.rightPanel {
  background: #00000056;
  flex-grow: 1;
}

/* DASBOARD CONTENTS */

h2 {
  padding: 0.5rem;
  margin: 0.5rem;
}

h3 {
  padding: 2rem;
  margin: 0.5rem;
}

/* NOTIFICATIONS */

ul.notifications {
  background: orange;
  color: black;
}

html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background: rgb(32, 31, 31);
  line-height: 1;
}

button * {
  outline: none !important;
}

p.content {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

div.content {
  background-color: #27272767;
}

div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 1062px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  background: #272727bc;
  color: ghostwhite;
  padding-bottom: 0.7rem;
}

/* AVATARS */

div.avatarHomar { 
  background: url(/static/media/homar.2bda2d85.png) center no-repeat;
}

div.avatarDuszek { 
  background: url(/static/media/duszek.b4199bd1.jpg) center no-repeat;
}

div.avatarMissing { 
  background: url(/static/media/missing.fb49545c.jpg) center no-repeat;
}

div.avatarMaria { 
  background: url(/static/media/niceisntit.256fe503.jpg) center no-repeat;
}

div.avatarSingomega { 
  background: url(/static/media/xechidna.f2bfd1c1.jpg) center no-repeat;
}

div.avatarXmxo { 
  background: url(/static/media/xmo.f5245834.jpg) center no-repeat;
}

div.avatarKitsu { 
  background: url(/static/media/kitsu.967c0fc6.jpg) center no-repeat;
}


/* SHADOWS */

@-webkit-keyframes shadow {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 3px black;
  }
  25% {
    box-shadow: 0.2vw 0.5vh 10px #27dfa8f1
  }
  76% {
    box-shadow: 0vw 1vh 3px black;
  }
  75%,
  77% {
    box-shadow: 0.1vw 0.5vh 10px #27dfa8f1
  }
}

@keyframes shadow {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 3px black;
  }
  25% {
    box-shadow: 0.2vw 0.5vh 10px #27dfa8f1
  }
  76% {
    box-shadow: 0vw 1vh 3px black;
  }
  75%,
  77% {
    box-shadow: 0.1vw 0.5vh 10px #27dfa8f1
  }
}

/* @keyframes shadow {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 5px black;
  }
  25% {
    box-shadow: 0vw 0vh 1rem rgb(55, 248, 255);
  }
  76% {
    box-shadow: 0vw 1vh 5px black;
  }
  75%,
  77% {
    box-shadow: 0vw 0vh 1rem rgb(55, 248, 255);
  }
} */

@-webkit-keyframes shadowDark {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 5px black;
  }
  50% {
    /* box-shadow: 1vw 1vh 1rem rgb(246, 188, 27); */
    box-shadow: 0vw 1vh 10px 15px rgba(0, 0, 0, 0);
  }
  75% {
    /* box-shadow: 1vw 1vh 1rem rgb(243, 0, 81); */
    box-shadow: 0vw 1vh 5px black;
  }
}

@keyframes shadowDark {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 5px black;
  }
  50% {
    /* box-shadow: 1vw 1vh 1rem rgb(246, 188, 27); */
    box-shadow: 0vw 1vh 10px 15px rgba(0, 0, 0, 0);
  }
  75% {
    /* box-shadow: 1vw 1vh 1rem rgb(243, 0, 81); */
    box-shadow: 0vw 1vh 5px black;
  }
}

@-webkit-keyframes rotateEmoji {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

@keyframes rotateEmoji {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

.boxShadowed {
  box-shadow: 0vw 1vh 5px black;
}

/* NAVBAR CSS */

.navbar {
  margin: auto;
  margin-top: 1rem;
  width: 95%;
  max-width: 1062px;
  background-color: #272727;
  -webkit-animation: infinite;
          animation: infinite;
  -webkit-animation-name: shadow;
          animation-name: shadow;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
  padding: 0rem;
  border-radius: 3px;
}

.navbarComponent {
  margin: 0.7rem;
  margin-bottom: 0.9rem;
  box-shadow: 0vw 1vh 5px black;
}

@media screen and (min-width: 1000px) {
  .navbarComponent {
    margin: 1.5rem;
    margin-bottom: 1.8rem;
    box-shadow: 0vw 1vh 5px black;
  }
}

div.dropdown-menu {
  min-width: 11vw;
}

div.dropdown-menu.show {
  padding: 0;
  background-color: #000000a8;
  width: 11vw;
  min-width: 130px;
}

div.dropdown-menu.show a {
  padding: 0;
  text-decoration: none;
}

div.dropdown-menu.show button {
  padding: 0;
  background-color: #00000004;
  outline: none;
}

div.dropdown-menu.show button :hover {
  background-color: #ce1ca7;
}

div.flexRow {
  display: flex;
  margin: 3%;
}

div.flexRow p {
  padding: 0;
  font-size: 1rem;
}

h1.logo {
  width: 35vw;
  font-size: 5rem;
  color: black;
  letter-spacing: 0.2rem;
  color: #ffffff;
}

@-webkit-keyframes warning {
  0%,
  100% {
    background-color: red;
  }
  50% {
    background-color: orange;
  }
}

@keyframes warning {
  0%,
  100% {
    background-color: red;
  }
  50% {
    background-color: orange;
  }
}

h1.menuButton {
  color: white;
  text-align: left;
  font-size: 0.9em;
  border-right: 1rem;
  padding: 0.5rem 2% 0.5rem 2%;
  margin: 0.5rem 2% 0.5rem 2%;
}

@-webkit-keyframes homarjumpy {
  0%,
  100% {
  }
  25% {
    -webkit-transform: translate(40vw, 0vh);
            transform: translate(40vw, 0vh);
  }
  50% {
    -webkit-transform: translate(10vw, 80vh);
            transform: translate(10vw, 80vh);
  }
  60% {
    -webkit-transform: translate(30vw, 60vh);
            transform: translate(30vw, 60vh);
  }
  80% {
    -webkit-transform: translate(-20vw, 80vh);
            transform: translate(-20vw, 80vh);
  }
  90% {
    -webkit-transform: translate(-30vw, 30vh);
            transform: translate(-30vw, 30vh);
  }
}

@keyframes homarjumpy {
  0%,
  100% {
  }
  25% {
    -webkit-transform: translate(40vw, 0vh);
            transform: translate(40vw, 0vh);
  }
  50% {
    -webkit-transform: translate(10vw, 80vh);
            transform: translate(10vw, 80vh);
  }
  60% {
    -webkit-transform: translate(30vw, 60vh);
            transform: translate(30vw, 60vh);
  }
  80% {
    -webkit-transform: translate(-20vw, 80vh);
            transform: translate(-20vw, 80vh);
  }
  90% {
    -webkit-transform: translate(-30vw, 30vh);
            transform: translate(-30vw, 30vh);
  }
}

img.homar {
  width: 45px;
  height: auto;
  position: absolute;
  margin: auto;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-animation: infinite;
          animation: infinite;
  -webkit-animation-name: homarjumpy;
          animation-name: homarjumpy;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

img.homar:active {
  -webkit-transform: scale(0.3, 1.3);
          transform: scale(0.3, 1.3);
}

/* react-strap CARD STYLES */

div.card {
  margin: 1rem;
  background-color: #2d333fb2;
}

div.card-header {
  background-color: #353b49b2;
}

div.card-footer {
  background-color: #353b49b2;
}

