nav.manifesto {
  max-width: 700px;
  animation-name: shadowDark;
  margin-top: 0;
}

div.manifesto.container {
  padding: 1rem;
  padding-bottom: 1.5rem;
  box-shadow: 0vw 1vh 5px black;
}

div.manifesto.container h1,
p {
  font-family: "Anonymous Pro", monospace;
  color: white;
}

h1.manifesto.title {
  font-family: "Anonymous Pro", monospace;
  margin-top: 0.5rem;
  font-size: 1rem;
}

p.paragraphLink {
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  margin: 0;
}

p.paragraphContent {  
  max-width: 700px;
}

div.paragraphLinks {
  padding: 1rem;
  padding-top: 0;
}

div.paragraphLinks li :hover {
  background-color: #ce1ca7;
}

button.manifestHeader {
  margin: auto;
  outline: none;
  border: 0;
}

div.tabHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

button.tabButton {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  text-decoration: none;
}

button.tabButton.titleButton {
  margin-right: auto; /*used to override justify-content; justify-self is cooming soon though*/
  /* font-size: 1.5em; */
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
}

button.tabButton.titleButton:focus {
  outline: none;
}

button.manifestHeader:focus {
  outline: none;
}

button.pageflip {
  font-size: 1em;
  max-width: 20vw;
  padding-right: 0.6rem;
}

button.pageflip:hover {
  text-decoration: underline;
}

button.titleButton:hover {
  border-left: dashed;
  border-width: 0.1em;
}

div.manifestContent {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 1.1;
}

@media screen and (min-width: 1000px) {
  div.manifestContent {
    line-height: 1.3;
  }
}

div.manifestContent p {
  margin-bottom: 1.5rem;
}
