/* COMMON */

div.outerContainer {
  margin: 2vh 5%;
  background: #272727bc;
}

div.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.post {
  background: #2e2e2e52;
  width: 90%;
  max-width: 700px;
  margin: 1rem;
}

div.post a {
  text-decoration: none;
}

/* div.post h3,
h5 {
  font-family: "Ubuntu", sans-serif;
} */

/* div.post span {
  color: red;
  font-size: 1em;
  font-family: 'News Cycle', sans-serif;
} */

/* POST ABSTRACT STYLING */

/* inplace */
div.postAbstract {
  background: #2e2e2e81;
  padding: 0.5rem;
  margin: 0.5rem;
  width: 95%;
  max-width: 650px;
  line-height: 1.2;
  padding-bottom: 1rem;
}

@media screen and (min-width: 1000px) {
  div.postAbstract {
    margin: 1.5rem;
    min-width: 700px;
  }
  
  div.abstractContent span
  {
    line-height: 1.4;
  }
}

/* abstract header */

div.postAbstract a {
  text-decoration: none;
}

div.abstractHeader {
  animation: infinite;
  animation-name: shadowDark;
  animation-duration: 8s;
}

div.abstractHeader {
  color: ghostwhite;
}

#abstractTitle span {
  font-size: 1.3rem;
}

span.abstractDetails {
  font-family: initial;
  font-size: 0.9em;
  /* font-style: italic; */
  /* padding-top: 1vh; */
}

span.abstractDetails.author {
  color: #5ef083;
}

span.abstractDetails.date {
  color: inherit;
}


/* abstract content */

div.abstractContent {
  color: #f1f1f1;
  text-shadow: #232323 2px 5px 10px;
  padding: 1rem 2rem;
}

div.abstractContent span {
  font-family: 'News Cycle', sans-serif;
}

/* abstract footer */

div.abstractFooter {
  /* background-color: red */
  width: 100%;
  display: flex;    
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.2rem 2rem;
  /* padding: 0.5rem;   */
  align-items: center
}
