/* GENERAL */
div.content {
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  padding-top: 1.5rem;
}

/* h1.productHeader {
  width: 100%;
  text-align: center;
} */

div.productHeader {
  background: #272727bc;
  animation: infinite;
  animation-name: shadowDark;
  animation-duration: 8s;
}

@keyframes flicker {
  0%,25%,27%,29%,77%,80%,100% {
    text-shadow: 0.4vw 0.4vh 1rem rgb(55, 248, 255), 0.2vw 0.2vh rgb(255, 55, 121);
  }
  26%,28%,78% {
    text-shadow: none;
  }
}

h1.productHeader {
  padding: 2vh 5vw 2vh 5vw;
	position: relative;
  text-align: center;
  font: 3em Helvetica, Sans-Serif;
	letter-spacing: -5px;
  color: rgba(255, 255, 255, 0.918);
  animation: infinite;
  animation-name: flicker;
  animation-duration: 8s;
}

.flex-container {
  padding: 0;
  margin: auto;

  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  justify-content: center;
}

.flex-item {
  padding: 0rem;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  color: white;
  font-size: 1em;
  text-align: center;
}

/* OUTRO PRODUCT LIST */
li.flex-item {
  height:auto;
  padding: 0.5rem;
  flex-grow: 1;
  background: #272727bc;
}

div.listedOutro {
  padding: 1rem;
}

img.listedOutro {  
  width: 100%;
  height:100%;
}

ul.flex-container {
  padding-top: 0rem;
}

/* OUTRO CUTENESS */
.flex-container.outro {
  max-width: 1200px;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-item.outro { 
  max-width: 400px;
  width: auto;
  height: auto;
  background: #272727bc;
  padding: 1rem;
}

img.flex-item.outro {
  max-width:95%;
  max-height:95%;
  background: none;
  opacity: 0.9;
  padding: 0;
}

div.flex-item.outro > p {
  text-align: left;
  line-height: 120%;
  font-size: 1em;
  font: 1rem Helvetica, ‘sans-serif’;
  letter-spacing: 2px;
}

#outroHeader {
  padding: 3vh;
  margin-bottom: -3vh;
  line-height: 1.5em;
  text-align: center;
  background: none;
  font-size : 1rem;
}

/* #outroDescription {
  align-self: center;
} */



