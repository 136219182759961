html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background: rgb(32, 31, 31);
  line-height: 1;
}

button * {
  outline: none !important;
}

p.content {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

div.content {
  background-color: #27272767;
}

div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 1062px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  background: #272727bc;
  color: ghostwhite;
  padding-bottom: 0.7rem;
}

/* AVATARS */

div.avatarHomar { 
  background: url(./../media/avatars/homar.png) center no-repeat;
}

div.avatarDuszek { 
  background: url(./../media/avatars/duszek.jpg) center no-repeat;
}

div.avatarMissing { 
  background: url(./../media/avatars/missing.jpg) center no-repeat;
}

div.avatarMaria { 
  background: url(./../media/avatars/niceisntit.jpg) center no-repeat;
}

div.avatarSingomega { 
  background: url(./../media/avatars/xechidna.jpg) center no-repeat;
}

div.avatarXmxo { 
  background: url(./../media/avatars/xmo.jpg) center no-repeat;
}

div.avatarKitsu { 
  background: url(./../media/avatars/kitsu.jpg) center no-repeat;
}


/* SHADOWS */

@keyframes shadow {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 3px black;
  }
  25% {
    box-shadow: 0.2vw 0.5vh 10px #27dfa8f1
  }
  76% {
    box-shadow: 0vw 1vh 3px black;
  }
  75%,
  77% {
    box-shadow: 0.1vw 0.5vh 10px #27dfa8f1
  }
}

/* @keyframes shadow {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 5px black;
  }
  25% {
    box-shadow: 0vw 0vh 1rem rgb(55, 248, 255);
  }
  76% {
    box-shadow: 0vw 1vh 5px black;
  }
  75%,
  77% {
    box-shadow: 0vw 0vh 1rem rgb(55, 248, 255);
  }
} */

@keyframes shadowDark {
  0%,
  50%,
  100% {
    box-shadow: 0vw 1vh 5px black;
  }
  50% {
    /* box-shadow: 1vw 1vh 1rem rgb(246, 188, 27); */
    box-shadow: 0vw 1vh 10px 15px rgba(0, 0, 0, 0);
  }
  75% {
    /* box-shadow: 1vw 1vh 1rem rgb(243, 0, 81); */
    box-shadow: 0vw 1vh 5px black;
  }
}

@keyframes rotateEmoji {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.boxShadowed {
  box-shadow: 0vw 1vh 5px black;
}

/* NAVBAR CSS */

.navbar {
  margin: auto;
  margin-top: 1rem;
  width: 95%;
  max-width: 1062px;
  background-color: #272727;
  animation: infinite;
  animation-name: shadow;
  animation-duration: 12s;
  padding: 0rem;
  border-radius: 3px;
}

.navbarComponent {
  margin: 0.7rem;
  margin-bottom: 0.9rem;
  box-shadow: 0vw 1vh 5px black;
}

@media screen and (min-width: 1000px) {
  .navbarComponent {
    margin: 1.5rem;
    margin-bottom: 1.8rem;
    box-shadow: 0vw 1vh 5px black;
  }
}

div.dropdown-menu {
  min-width: 11vw;
}

div.dropdown-menu.show {
  padding: 0;
  background-color: #000000a8;
  width: 11vw;
  min-width: 130px;
}

div.dropdown-menu.show a {
  padding: 0;
  text-decoration: none;
}

div.dropdown-menu.show button {
  padding: 0;
  background-color: #00000004;
  outline: none;
}

div.dropdown-menu.show button :hover {
  background-color: #ce1ca7;
}

div.flexRow {
  display: flex;
  margin: 3%;
}

div.flexRow p {
  padding: 0;
  font-size: 1rem;
}

h1.logo {
  width: 35vw;
  font-size: 5rem;
  color: black;
  letter-spacing: 0.2rem;
  color: #ffffff;
}

@keyframes warning {
  0%,
  100% {
    background-color: red;
  }
  50% {
    background-color: orange;
  }
}

h1.menuButton {
  color: white;
  text-align: left;
  font-size: 0.9em;
  border-right: 1rem;
  padding: 0.5rem 2% 0.5rem 2%;
  margin: 0.5rem 2% 0.5rem 2%;
}

@keyframes homarjumpy {
  0%,
  100% {
  }
  25% {
    transform: translate(40vw, 0vh);
  }
  50% {
    transform: translate(10vw, 80vh);
  }
  60% {
    transform: translate(30vw, 60vh);
  }
  80% {
    transform: translate(-20vw, 80vh);
  }
  90% {
    transform: translate(-30vw, 30vh);
  }
}

img.homar {
  width: 45px;
  height: auto;
  position: absolute;
  margin: auto;
  transition: transform 0.5s;
  animation: infinite;
  animation-name: homarjumpy;
  animation-duration: 15s;
  animation-play-state: running;
}

img.homar:active {
  transform: scale(0.3, 1.3);
}

/* react-strap CARD STYLES */

div.card {
  margin: 1rem;
  background-color: #2d333fb2;
}

div.card-header {
  background-color: #353b49b2;
}

div.card-footer {
  background-color: #353b49b2;
}
